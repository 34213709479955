import React, { useContext } from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Link from "next/link";
// import Image from "next/image";
import Image from 'next/legacy/image'
import dynamic from "next/dynamic";
import Carousel from 'react-material-ui-carousel'

// Context maintains theme settings across pages
import { ThemeContext } from "../components/ThemeProvider";

// Components
const SearchTool = dynamic(() => import("../components/SearchTool"));
const SuggestBlogPostsHorizontal = dynamic(() => import("../components/SuggestBlogPostsHorizontal"));
import SeoHead from "../components/SeoHead";

// Assets
import rs6Day from "../public/images/rs6.2000.webp";
import rs6Night from "../public/images/rs6-night.big.webp";
import e30Day from "../public/images/e30day.webp";
import porscheNight from "../public/images/porschenight.webp";
import m2cDay from "../public/images/bmwm2c_day.webp";
import m2cnight from "../public/images/bmwm2c_night.webp";
import m4Day from "../public/images/bmwm4_day.webp";
import m4night from "../public/images/bmwm4_night.webp";
import challengerDay from "../public/images/dodgechallengerday.webp";
import challengerNight from "../public/images/dodgechallengernight.webp";
import aventadorNight from "../public/images/aven1.webp";
import aventadorDay from "../public/images/aven2.webp";
import activeAutoworksLogo from "../public/images/logos/active_autoworks_logo.webp";
import vrSpeedFactory from "../public/images/logos/vr_speed_factory.webp";
import akrapovicLogo from "../public/images/logos/akrapovic_logo.svg";
import remusLogo from "../public/images/logos/REMUS-Logo-1200x221.webp";

// https://jsonld.com/organization/
function addJsonLd() {
  return {
    __html: `{
      "@context": "https://schema.org", 
      "@type": "Organization",
      "name": "Search Exhaust",
      "legalName": "Search Exhaust",
      "logo": "https://searchexhaust.com/images/logo.192.webp",
      "foundingDate": "2022",
      "url": "https://searchexhaust.com"
    }`,
  };
}


const Index = () => {
  const { isDarkTheme } = useContext(ThemeContext);

  // Handle Dark Mode
  let rs6 = isDarkTheme ? rs6Night : rs6Day;
  let secondFullWidthImageFile = isDarkTheme ? porscheNight : e30Day;
  let m2c = isDarkTheme ? m2cnight : m2cDay;
  let m4 = isDarkTheme ? m4night : m4Day;
  let challenger = isDarkTheme ? challengerNight : challengerDay;
  let aventador = isDarkTheme ? aventadorNight : aventadorDay;

  const rootStyles = {
    padding: "0",
    color: "text.primary",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column"
  };

  const searchContainer = {
    width: "100%",
    backgroundColor: "background.secondary",
    textAlign: "center",
    align: "center",
    justify: "center",
    margin: "0",
    color: "text.secondary",
    fontSize: "2.5rem",
    padding: "3vh"
  };

  const searchContainerMain = {
    width: "100%",
    backgroundColor: "background.secondary",
    textAlign: "center",
    align: "center",
    borderRadius: "45px",
    justify: "center",
    margin: "0",
    color: "text.secondary",
    fontSize: "2.5rem",
    padding: "3vh"
  };

  const headerTextContainer = {
    marginBottom: "5vh",
    borderRadius: "15px"
  };

  const secondFullWidthImageTextContainer = {
    marginBottom: "5vh",
    padding: "20px",
    textShadow: "0px 0px 0px  #000",
    background: "rgba(0,0,0,0.5)",
    boxShadow: 2,
    borderRadius: "5px"
  };

  const overlay = {
    position: "absolute",
    objectFit: "cover",
    layout: 'fill',
    width: "100%",
    height: "100%", // added height property
    backgroundColor: "#5BC2E7"
  };

  const h2Titles = {
    color: "text.secondary",
    fontSize: "2.5rem"
  };

  const topOfPage = {
    display: "flex",
    width: "100vw",
    justifyContent: "center",
    minHeight: "70vh",
    maxWidth: "100%",
    width: "100vw",
    bgcolor: "background.paper",
    color: "white",
    borderTop: "1px solid #000",
    borderBottom: "1px solid #000",
    backgroundPosition: "center",
    backgroundSize: "cover",
    p: 4,
    overflowX: "hidden"
  };
  const secondFullWidthImage = {
    minHeight: "70vh",
    maxWidth: "100%",
    width: "100vw",
    bgcolor: "background.paper",
    color: "white",
    borderTop: "1px solid #000",
    borderBottom: "1px solid #000",
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${secondFullWidthImageFile.src})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    p: 4,
    overflowX: "hidden"
  };

  const featuredCompaniesSection = {
    bgcolor: "background.secondary",
    paddingTop: "2vh",
    paddingBottom: "5vh",
    textAlign: "center"
  };


  var items = [
    {
      name: 'Discover Your Perfect Exhaust',
      description: 'Enter Your Make and Model',
      backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${rs6.src})`,
    },
    {
      name: 'Compare Aftermarket Exhausts',
      description: 'Find an Exhaust',
      backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${secondFullWidthImageFile.src})`,
    }
  ];

  function Item(props) {
    return (
      <Container maxWidth="false" sx={{ ...topOfPage, minHeight: "70vh", backgroundImage: props.item.backgroundImage }}>
        <Container maxWidth="sm" sx={headerTextContainer}>
          <Typography variant="h1" sx={{ fontSize: '4rem', textAlign: 'center', textShadow: '2px 2px 1px #000' }}>
            {props.item.name}
          </Typography>
          <br />
          <Box sx={searchContainerMain}>
            <Typography variant="h4" sx={h2Titles}>
              {props.item.description}
            </Typography>
            <SearchTool />
          </Box>
        </Container>
      </Container>

    );
  };


  return (
    <div>
      <SeoHead
        title="Search Exhaust - #1 Aftermarket Exhaust Audio and Video Library"
        description="Discover your perfect aftermarket exhaust with Search Exhaust, the worlds largest curated library of aftermarket exhaust audio and videos in the world."
        canonicalLink="https://searchexhaust.com"
        jsonLd={addJsonLd()}
      />

      <Carousel interval="14000" duration="1500" animation="slide">
        {items.map((item, i) => (
          <Item key={i} item={item} />
        ))}
      </Carousel>

      {/* <div sx={overlay}>
        <Container maxWidth={false} align="center" sx={topOfPage}>
          <Container maxWidth="md">
            <Container maxWidth="sm" sx={headerTextContainer}>
              <Typography variant="h1" sx={{ fontSize: "4rem", textAlight: "center" , textShadow: "2px 2px 1px  #000"}}>
                Discover Your Perfect Exhaust
              </Typography>
              {<br />}
              <Box sx={searchContainerMain}>
              <Typography variant="h4" sx={{h2Titles, textShadow: "1px 1px 1px "}}>
          Enter Your Make and Model
        </Typography>
        <SearchTool />
      </Box>
            </Container>
          </Container>
        </Container>
      </div> */}
      <Box sx={featuredCompaniesSection}>
        {/* <Typography variant="h2" sx={h2Titles}>
          Featured Companies
        </Typography> */}
        <Grid container spacing={2} direction="row" alignItems="center" sx={{ mt: "1vh", mb: "1vh" }}>
          <Grid item xs={3}>
            <div>
              <Link style={{ textDecoration: "none", color: "unset" }} href="/search/BMW/M2" passHref prefetch={false}>
                <Image alt="Active Autoworks Logo" src={activeAutoworksLogo} quality={100} height={90} width={329} unoptimized />
              </Link>
            </div>
          </Grid>
          <Grid item xs={3}>
            <Link style={{ textDecoration: "none", color: "unset" }} href="/search/BMW/M3" passHref prefetch={false}>
              <Image alt="Remus Logo" src={remusLogo} quality={100} height={221} width={1200} unoptimized />
            </Link>
          </Grid>
          <Grid item xs={3}>
            <Link style={{ textDecoration: "none", color: "unset" }} href="/search/BMW/M3" passHref prefetch={false}>
              <Image alt="VR Speed Factory Logo" src={vrSpeedFactory} quality={100} height={83} width={285} unoptimized />
            </Link>
          </Grid>
          <Grid item xs={3}>
            <Link style={{ textDecoration: "none", color: "unset" }} href="/search/BMW/M3" passHref prefetch={false}>
              <Image alt="Akrapovic Logo" src={akrapovicLogo} quality={100} height={86} width={384} unoptimized />
            </Link>
          </Grid>
        </Grid>
      </Box>
      <Container align="center" sx={{ paddingBottom: "3vh" }}>
        <Grid container spacing={2} direction="row" alignItems="center" justifyContent="center">
          <Grid item xs={12}>
            {/* <Typography color="text.secondary" variant="h2" sx={h2Titles}>
              Featured Cars
            </Typography> */}
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <Paper elevation={4}>
              <Card>
                <CardContent>
                  {/* <CardMedia component="img" height="315" width="75" alt="Search Exhaust for BMW m2 competition" image={m2c.src} /> */}
                  <Image
                    src={m2c.src}
                    alt="BMW M2 Competition"
                    width={675}
                    height={900}
                    loading="lazy"
                    objectFit="cover"
                    objectPosition="center"
                    onError={() => {
                      // handle image not found
                    }}
                    unoptimized
                  />
                  <Typography gutterBottom variant="h3" sx={{ fontSize: "1.5rem" }} component="div">
                    BMW M2
                  </Typography>
                  <Button variant="contained" color="success" href={"/search/BMW/M2"}>
                    <Typography>Search Exhaust</Typography>
                  </Button>
                </CardContent>
              </Card>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <Paper elevation={4}>
              <Card>
                <CardContent>
                  {/* <CardMedia component="img" height="315" width="75" alt="Search Exhaust for BMW M4" image={m4.src} /> */}
                  <Image
                    src={m4.src}
                    alt="BMW M4 Day and Night Images"
                    width={675}
                    height={900}
                    loading="lazy"
                    objectFit="cover"
                    objectPosition="center"
                    onError={() => {
                      // handle image not found
                    }}
                    unoptimized
                  />
                  <Typography gutterBottom variant="h3" sx={{ fontSize: "1.5rem" }} component="div">
                    BMW M4
                  </Typography>
                  <Button variant="contained" color="success" href={"/search/BMW/M4"}>
                    <Typography>Search Exhaust</Typography>
                  </Button>
                </CardContent>
              </Card>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <Paper elevation={4}>
              <Card>
                <CardContent>
                  {/* <CardMedia component="img" height="315" width="75" alt="Search Exhaust for Challenger" image={challenger.src} /> */}
                  <Image
                    src={challenger.src}
                    alt="Dodge Challenger"
                    width={675}
                    height={900}
                    loading="lazy"
                    objectFit="cover"
                    objectPosition="center"
                    onError={() => {
                      // handle image not found
                    }}
                    unoptimized
                  />
                  <Typography gutterBottom variant="h3" sx={{ fontSize: "1.5rem" }} component="div">
                    Dodge Challenger
                  </Typography>
                  <Button variant="contained" color="success" href={"/search/Dodge/Challenger"}>
                    <Typography>Search Exhaust</Typography>
                  </Button>
                </CardContent>
              </Card>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <Paper elevation={4}>
              <Card>
                <CardContent>
                  {/* <CardMedia component="img" height="315" width="75" alt="Search Exhaust for Aventador" image={aventador.src} /> */}
                  <Image
                    src={aventador.src}
                    alt="Lamborghini Aventador"
                    width={675}
                    height={900}
                    loading="lazy"
                    objectFit="cover"
                    objectPosition="center"
                    onError={() => {
                      // handle image not found
                    }}
                    unoptimized
                  />
                  <Typography gutterBottom variant="h3" sx={{ fontSize: "1.485rem" }} component="div">
                    Lamborghini Aventador
                  </Typography>
                  <Button variant="contained" color="success" href={"/search/Lamborghini/Aventador"}>
                    <Typography>Search Exhaust</Typography>
                  </Button>
                </CardContent>
              </Card>
            </Paper>
          </Grid>
        </Grid>
      </Container>
      <SuggestBlogPostsHorizontal />
      {/* <Box sx={secondFullWidthImage} /> */}
      <div sx={overlay}>
        <Container maxWidth={false} align="left" sx={secondFullWidthImage}>
          <Box maxWidth="sm" sx={secondFullWidthImageTextContainer}>
            <Typography variant="h2" sx={{ fontSize: "2rem" }}>
              THE LARGEST ONLINE EXHAUST GALLERY
            </Typography>
            {<br />}
            <Typography variant="h3" sx={{ fontSize: "1.5rem" }}>
              Search exhausts on the most extensive library of real aftermarket exhaust audio and videos in the world. Discover your
              perfect performance exhaust mod today! Use the search bar above or below to discover exhaust mods that fit your car or play around with your dream car's exhaust
              note.
            </Typography>
          </Box>
        </Container>
      </div>
      <Box sx={searchContainer}>
        <Typography variant="h2" sx={h2Titles}>
          Rev Up Your Ride with the Ultimate Exhaust Comparison Tool
        </Typography>
        <SearchTool />
      </Box>
    </div>
  );
};

export default Index;
